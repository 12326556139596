import Stack from '@mui/material/Stack';
import { Score } from '../../../../components/Score/Score';

export function Environment({ company }) {
  if (!company.sustainability_dimensions) {
    return null;
  }
  const {
    resource_management,
    energy_and_climate_change, environmental,
    environmental_policy_and_reporting,
  } = company.sustainability_dimensions;
  return (
    <>
      <Stack direction="row" justifyContent="center" mb={1}>
        <Score overall={environmental} title="Environment" />
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Score overall={energy_and_climate_change} title="Energy & Climate" />
        <Score overall={environmental_policy_and_reporting} title="Policy & Reporting" />
        <Score overall={resource_management} title="Resource Management" />
      </Stack>
    </>
  );
}
