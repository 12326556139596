import React, { createContext, useState } from 'react';

export const SnackbarContext = createContext();

export function SnackbarProvider({ children }) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');

  const openSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <SnackbarContext.Provider value={{
      snackbarOpen, snackbarMessage, snackbarSeverity, openSnackbar, closeSnackbar,
    }}
    >
      {children}
      {/* Render the Snackbar component here */}
    </SnackbarContext.Provider>
  );
}
