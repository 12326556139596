import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { QUERIES } from '../../../constants/company';
import { Overall } from './Queries/Overall';
import { Environment } from './Queries/Environment';
import { Social } from './Queries/Social';
import { Climate } from './Queries/Climate';
import { DEI } from './Queries/DEI';
import { Biodiversity } from './Queries/Biodiversity';
import { HumanRights } from './Queries/HumanRights';

export function SustainabilityPerformance({ company, activeQuery, onChangeQuery }) {
  const theme = useTheme();

  const performance = [
    { title: 'Poor', bg: theme.palette.scores.error.main },
    { title: 'Moderate', bg: theme.palette.scores.warning.main },
    { title: 'Good', bg: theme.palette.scores.success.main },
  ];

  const queriesComponent = {
    overall: (<Overall {...{ company }} />),
    environment: (<Environment {...{ company }} />),
    social: (<Social {...{ company }} />),
    climate: (<Climate {...{ company }} />),
    DEI: (<DEI {...{ company }} />),
    biodiversity: (<Biodiversity {...{ company }} />),
    human_rights: (<HumanRights {...{ company }} />),
  };

  return (
    <Box>
      <Typography variant="h6">Sustainability Performance Rank</Typography>
      <Box sx={{
        backgroundColor: theme.palette.gray.light, pt: 3, px: 5, pb: 2, borderRadius: 2,
      }}
      >
        {
          queriesComponent[activeQuery.value]
        }
        <Stack direction="row" sx={{ width: '100%', mt: 3 }}>
          {
            performance.map(({ title, bg }) => (
              <Box
                key={title}
                sx={{
                  backgroundColor: bg,
                  textAlign: 'center',
                  flexGrow: 1,
                  p: 1,
                  '&:first-of-type': {
                    borderRadius: '4px 0 0 4px',
                  },
                  '&:last-child': {
                    borderRadius: '0 4px 4px 0',
                  },
                }}
              >
                <Typography variant="body2" color="white">{title}</Typography>
              </Box>
            ))
          }
        </Stack>
      </Box>
      <Box sx={{
        backgroundColor: theme.palette.gray.light, py: 2, px: 3, mt: 2, borderRadius: 2,
      }}
      >
        <Typography variant="h6">Query Tool</Typography>
        <Stack direction="row" spacing={2} sx={{ mt: 1, flexWrap: 'wrap' }} useFlexGap>
          {
            QUERIES.map((query) => (
              <Chip
                key={query.value}
                sx={{ fontWeight: 'medium', cursor: 'pointer' }}
                variant={activeQuery.value === query.value ? 'primary' : 'white'}
                size="small"
                label={query.label}
                onClick={() => onChangeQuery(query)}
              />
            ))
          }
        </Stack>
      </Box>
    </Box>
  );
}
