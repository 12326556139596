import React from 'react';
import Box from '@mui/material/Box';
import { DashboardLayer } from '../../layouts/DashboardLayer';

export function SavedCompanies() {
  return (
    <DashboardLayer>
      <Box sx={{ mt: 5 }}>
        Coming soon
      </Box>
    </DashboardLayer>
  );
}
