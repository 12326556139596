import { useContext, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useAuthHeader } from 'react-auth-kit';
import size from 'lodash/size';
import { CompareCompaniesBox, Header } from '../components';
import { setAxiosToken } from '../utils/init';
import { CompareContext } from '../utils/context';

const Container = styled(Grid)`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

export function DashboardLayer({ children }) {
  const header = useAuthHeader();
  const { compareCompanies } = useContext(CompareContext);
  const token = header();
  const compareCompaniesIsPresent = size(compareCompanies) > 0;

  useEffect(() => {
    setAxiosToken(token);
  }, [token]);

  return (
    <Container pb={compareCompaniesIsPresent ? 10 : 0}>
      <Header />
      <Grid sx={{
        pt: 5,
        pb: 6,
        px: { xs: 2, md: 0 },
        width: '100%',
        maxWidth: '991px',
        margin: '0 auto',
        flexGrow: 1,
      }}
      >
        {children}
      </Grid>
      {
        compareCompaniesIsPresent && (
          <CompareCompaniesBox />
        )
      }
    </Container>
  );
}
