import { useEffect, useState, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import debounce from 'lodash/debounce';
import { useNavigate } from 'react-router-dom';
import { searchCompanies } from '../../utils/api/companies';

export function CompaniesAutocomplete({ size = 'small', placeholder = 'Search...' }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const onChange = (e, value) => {
    navigate(`/company/${value.id}`);
  };

  const fetchData = async (name_starts) => {
    if (name_starts.length >= 3) {
      const res = await searchCompanies({ name_starts });
      setOptions(res?.data ? res.data : []);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceLoadData = useCallback(debounce(fetchData, 1000), []);

  useEffect(() => {
    debounceLoadData(inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
      setInputValue('');
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      sx={{ width: '100%' }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={onChange}
      clearOnBlur
      autoSelect={false}
      value={null}
      size={size}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          onChange={handleInputChange}
          value={inputValue}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
