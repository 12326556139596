import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { DetailContent } from '../../../../components';

export function SustainabilityMission({ company }) {
  const theme = useTheme();
  const {
    mission, pillar_1, pillar_2, pillar_3,
  } = company.sustainability_details || {};

  return (
    <>
      <DetailContent title="Mission">
        <Typography variant="body2" color={!mission && theme.palette.gray.middle}>
          {
            mission || 'No mission statement found.'
          }
        </Typography>
      </DetailContent>
      <DetailContent title="Pillars">
        { pillar_1 && <Typography variant="body2" mb={1}>{pillar_1}</Typography> }
        { pillar_2 && <Typography variant="body2" mb={1}>{pillar_2}</Typography> }
        { pillar_3 && <Typography variant="body2" mb={1}>{pillar_3}</Typography> }
        { (!pillar_1 && !pillar_2 && !pillar_3) && (
          <Typography variant="body2" mb={1} color={theme.palette.gray.middle}>
            No pillars found
          </Typography>
        ) }
      </DetailContent>
    </>
  );
}
