import React, { useMemo, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
  Route, Routes, BrowserRouter, Navigate,
} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { AuthProvider, useIsAuthenticated } from 'react-auth-kit';

import {
  Home, Login, ForgotPassword, ResetPassword, Audit, Company,
} from './containers';
import { CustomSnackbar } from './components';

import { theme } from './theme';
import { SnackbarProvider } from './context/SnackbarContext/SnackbarContext';
import { CompareContext } from './utils/context';
import { SavedCompanies } from './containers/SavedCompanies/SavedCompanies';

function Private({ Component }) {
  const isAuthenticated = useIsAuthenticated();
  const auth = isAuthenticated(); // your logic
  return auth ? <Component /> : <Navigate to="/login" />;
}

function Public({ Component }) {
  const isAuthenticated = useIsAuthenticated();
  const auth = isAuthenticated();
  return !auth ? <Component /> : <Navigate to="/" />;
}

function App() {
  const [compareCompanies, setCompareCompanies] = useState(
    JSON.parse(localStorage.getItem('compareCompanies')) || [],
  );

  const addCompareCompany = (company) => {
    if (compareCompanies?.some((item) => item.id === company.id)) return;
    const companies = [...compareCompanies, company];
    setCompareCompanies(companies);
    localStorage.setItem('compareCompanies', JSON.stringify(companies));
  };

  const removeCompareCompany = (company) => {
    const filteredCompanies = compareCompanies.filter((compareCompany) => compareCompany.id !== company.id);
    setCompareCompanies(filteredCompanies);
    localStorage.setItem('compareCompanies', JSON.stringify(filteredCompanies));
  };

  const removeAllCompareCompanies = () => {
    setCompareCompanies([]);
    localStorage.setItem('compareCompanies', JSON.stringify([]));
  };

  const value = useMemo(
    () => ({
      compareCompanies,
      addCompareCompany,
      removeCompareCompany,
      removeAllCompareCompanies,
    }),
    [compareCompanies],
  );

  return (
    <AuthProvider
      authType="localstorage"
      authName="_auth"
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider>
          <CompareContext.Provider value={value}>
            <BrowserRouter>
              <Routes>
                <Route path="/login" element={<Public Component={Login} />} />
                <Route path="/forgot-password" element={<Public Component={ForgotPassword} />} />
                <Route path="/reset-password" element={<Public Component={ResetPassword} />} />
                <Route path="/home" element={<Private Component={Home} />} />
                <Route path="/audit/:id" element={<Private Component={Audit} />} />
                <Route path="/company/:id" element={<Private Component={Company} />} />
                <Route path="/saved-companies" element={<Private Component={SavedCompanies} />} />
                <Route
                  path=""
                  element={<Navigate to="/home" replace />}
                />
              </Routes>
            </BrowserRouter>
          </CompareContext.Provider>
          <CustomSnackbar />
        </SnackbarProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
