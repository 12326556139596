import Stack from '@mui/material/Stack';
import { Score } from '../../../../components/Score/Score';

export function HumanRights({ company }) {
  if (!company.sustainability_dimensions) {
    return null;
  }
  const {
    human_rights_and_supply_chain,
  } = company.sustainability_dimensions;
  return (
    <Stack direction="row" justifyContent="center">
      <Score overall={human_rights_and_supply_chain} title="Human Rights Overall" />
    </Stack>
  );
}
