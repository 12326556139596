import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { DashboardLayer } from '../../layouts/DashboardLayer';
import { CompaniesTable } from '../../components';

export function Audit() {
  const [auditName, setAuditName] = useState('');
  const [formData, setFormData] = useState({ name: 'Ryan’s Comparison' });
  const [editMode, setEditMode] = useState(false);

  const toggleEditMode = () => {
    setEditMode((prev) => !prev);
  };

  const updateAudit = () => {
    if (auditName.trim()) {
      setFormData({ name: auditName });
      setEditMode(false);
    } else {
      setAuditName(formData.name);
      setEditMode(false);
    }
  };

  const onBlur = () => {
    updateAudit();
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      updateAudit();
    }
  };

  return (
    <DashboardLayer>
      <Box>
        <Typography variant="h3">
          Company Comparison
        </Typography>
        <Typography variant="body1" sx={{ marginTop: 3 }}>
          Start a new media audit below or view past ones.
        </Typography>
      </Box>
      <Box mt={2}>
        {
          editMode ? (
            <TextField
              name="name"
              size="small"
              label="Audit name"
              type="text"
              value={auditName}
              onChange={(e) => setAuditName(e.target.value.trim())}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
            />
          ) : (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="body1">
                {formData.name}
              </Typography>
              <IconButton aria-label="edit" onClick={toggleEditMode}>
                <BorderColorIcon />
              </IconButton>
            </Stack>
          )
        }
      </Box>

      <Box sx={{ mt: 2 }}>
        <CompaniesTable />
      </Box>
    </DashboardLayer>
  );
}
