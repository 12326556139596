import { useState } from 'react';

export const useModal = () => {
  const [openModal, setOpenModal] = useState(false);
  const [modalProps, setModalProps] = useState({});

  const handleClickOpen = (modalData) => {
    setOpenModal(true);
    setModalProps(modalData);
  };

  const handleClose = () => {
    setOpenModal(false);
    setModalProps({});
  };

  const handleOpen = (modalData) => () => {
    handleClickOpen(modalData);
  };

  return {
    openModal,
    modalProps,
    handleClose,
    handleOpen,
  };
};
