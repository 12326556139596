import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { DashboardLayer } from '../../layouts/DashboardLayer';
import { CompaniesAutocomplete } from '../../components';

export function Home() {
  return (
    <DashboardLayer>
      <Typography variant="h2" align="center">
        Welcome to All Planet
      </Typography>
      <Typography variant="h5" align="center" sx={{ marginBottom: 5 }}>
        Search for companies to compare environmental impact.
      </Typography>
      <Grid container justifyContent="center" pb={5}>
        <CompaniesAutocomplete size="medium" />
      </Grid>
    </DashboardLayer>
  );
}
