import Stack from '@mui/material/Stack';
import { Score } from '../../../../components/Score/Score';

export function Social({ company }) {
  if (!company.sustainability_dimensions) {
    return null;
  }
  const {
    social,
    employees,
    communities,
  } = company.sustainability_dimensions;
  return (
    <>
      <Stack direction="row" justifyContent="center" mb={1}>
        <Score overall={social} title="Social Overall" />
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Score overall={employees} title="Employees" />
        <Score overall={communities} title="Commnunities" />
      </Stack>
    </>
  );
}
