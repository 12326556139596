import React, { useEffect, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';
import size from 'lodash/size';
import Avatar from '@mui/material/Avatar';
import { DashboardLayer } from '../../layouts/DashboardLayer';
import { CompanyDetails } from './components/CompanyDetails/CompanyDetails';
import { Certifications } from './components/Certifications';
import { NonProfitPartners } from './components/NonProfitPartners';
import { Priority } from './components/Priority';
import { retrieveCompany } from '../../utils/api/companies';
import { SustainabilityPerformance } from './components/SustainabilityPerformance';
import { QUERIES } from '../../constants/company';
import { makeAvatarPlaceholder } from '../../utils/helper';
// import { CompareContext } from '../../utils/context';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`company-tabpanel-${index}`}
      aria-labelledby={`company-tab-${index}`}
      sx={{ pt: 4 }}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </Box>
  );
}

export function Company() {
  const { id } = useParams();
  const [value, setValue] = useState(0);
  const [company, setCompany] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [activeQuery, setActiveQuery] = useState(QUERIES[0]);

  // const { addCompareCompany, compareCompanies } = useContext(CompareContext);

  // const addedCompareCompany = compareCompanies.some((item) => item.id === company.id);
  //
  // const onAddToCompare = () => {
  //   addCompareCompany(company);
  // };

  const onChangeQuery = (query) => {
    setActiveQuery(query);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchData = async () => {
    setIsLoading(true);
    const res = await retrieveCompany({ company_id: id });
    setCompany(res?.data || {});
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    setActiveQuery(QUERIES[0]);
  }, [id]);

  const TABS = useMemo(() => [
    {
      label: 'Accreditations',
      component: <Certifications company={company} />,
    },
    {
      label: 'Non-profit partners',
      component: <NonProfitPartners />,
    },
    {
      label: 'Priority SDG\'s',
      component: <Priority />,
    },
  ], [company.id]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  return (
    <DashboardLayer>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container mb={3}>
            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Avatar
                src={company?.logo_url}
                sx={{
                  width: 100,
                  height: 100,
                  '& img': {
                    objectFit: 'contain',
                  },
                }}
                variant="rounded"
              >
                {makeAvatarPlaceholder(company?.name)}
              </Avatar>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="h5" mb={2}>{company?.name}</Typography>
              {
                size(company?.industries) > 0 && (
                  <Typography variant="body2" mb={1}>
                    { company.industries.join(', ') }
                  </Typography>
                )
              }
              {
                company.website_url && (
                  <a
                    target="_blank"
                    href={`https://${company.website_url}`}
                    rel="noreferrer"
                  >
                    {company.website_url}
                  </a>
                )
              }

            </Grid>
            <Grid item xs={2}>
              <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                {/* <Button color="primary" variant="outlined" onClick={onAddToCompare} disabled={addedCompareCompany}> */}
                {/*  {addedCompareCompany ? 'Added to compare' : 'Add to compare'} */}
                {/* </Button> */}
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <SustainabilityPerformance {...{ company, activeQuery, onChangeQuery }} />
            </Grid>
            <Grid item xs={12} md={7}>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    {
                      TABS.map(({ label }) => (
                        <Tab key={label} label={label} />
                      ))
                    }
                  </Tabs>
                </Box>
                {
                  TABS.map(({ component, label }, index) => (
                    <TabPanel key={label} value={value} index={index}>
                      {component}
                    </TabPanel>
                  ))
                }
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 5, marginBottom: 5 }} />
      <CompanyDetails company={company} />
    </DashboardLayer>
  );
}
