import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export function DetailContent({ title, children, justifyContent = 'flex-start' }) {
  const theme = useTheme();

  return (
    <Box mb={2}>
      <Typography variant="body1" fontWeight="bold" color={theme.palette.gray.main}>
        {title}
      </Typography>
      <Box sx={{ pt: 0 }}>
        {children}
      </Box>
    </Box>
  );
}
