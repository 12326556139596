import Grid from '@mui/material/Grid';
import { styled, useTheme } from '@mui/material/styles';
import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
// import { Header } from '../components';
import LogoIcon from '../images/logo.png';

const Container = styled(Grid)`
  min-height: 100vh;
`;

const ChildrenWrapper = styled(Grid)`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  background-color: rgb(245, 245, 245);
`;

export const AuthBox = styled(Box)`
  width: 100%;
  max-width: 600px;
`;

export function AnAuthLayer({ children, title }) {
  const theme = useTheme();

  return (
    <Container container direction="column">
      {/* <Header /> */}
      <ChildrenWrapper>
        <AuthBox>
          <Stack direction="row" justifyContent="center">
            <img src={LogoIcon} alt="Logo" width="200px" />
          </Stack>
          {
            title && (
              <Typography variant="h4" align="center" color={theme.palette.secondary.light} sx={{ marginBottom: 1, mt: 2 }}>
                {title}
              </Typography>
            )
          }
          {children}
        </AuthBox>
      </ChildrenWrapper>
    </Container>
  );
}
