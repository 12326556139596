import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import styled from '@mui/material/styles/styled';
import {
  DataGrid,
} from '@mui/x-data-grid';

const DataGridStyled = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
    outline: 'none !important',
  },
  '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus':
    {
      outline: 'none !important',
    },
}));

const columns = [
  {
    field: 'companyName',
    headerName: 'Company name',
    flex: 1,
    sortable: false,
  },
  {
    field: 'overallScore',
    headerName: 'Overall Score',
    flex: 1,
    sortable: false,
  },
  {
    field: 'environmentalScore',
    headerName: 'Environmental Score',
    flex: 1,
    sortable: false,
  },
  {
    field: 'socialScore',
    headerName: 'Social Score',
    flex: 1,
    width: 160,
  },
  {
    field: 'actions',
    headerName: '',
    flex: 1,
    sortable: false,
    align: 'right',
    renderCell: () => (<Button>Add</Button>),
  },
];

const rows = [
  {
    id: 1,
    companyName: 'Company 1',
    overallScore: 'A',
    environmentalScore: 'B',
    socialScore: 'C',
  },
  {
    id: 2,
    companyName: 'Company 2',
    overallScore: 'A',
    environmentalScore: 'B',
    socialScore: 'C',
  },
  {
    id: 3,
    companyName: 'Company 3',
    overallScore: 'A',
    environmentalScore: 'B',
    socialScore: 'C',
  },
  {
    id: 4,
    companyName: 'Company 4',
    overallScore: 'A',
    environmentalScore: 'B',
    socialScore: 'C',
  },
  {
    id: 5,
    companyName: 'Company 5',
    overallScore: 'A',
    environmentalScore: 'B',
    socialScore: 'C',
  },
  {
    id: 6,
    companyName: 'Company 6',
    overallScore: 'A',
    environmentalScore: 'B',
    socialScore: 'C',
  },
  {
    id: 7,
    companyName: 'Company 7',
    overallScore: 'A',
    environmentalScore: 'B',
    socialScore: 'C',
  },
  {
    id: 8,
    companyName: 'Company 8',
    overallScore: 'A',
    environmentalScore: 'B',
    socialScore: 'C',
  },
  {
    id: 9,
    companyName: 'Company 9',
    overallScore: 'A',
    environmentalScore: 'B',
    socialScore: 'C',
  },
];

export function CompaniesTable() {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  useEffect(() => {
    setTotalCount(20);
    setLoading(false);
  }, []);

  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGridStyled
        rows={rows}
        columns={columns}
        filterMode="server"
        sortingMode="server"
        pageSizeOptions={[5]}
        rowCount={totalCount}
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        loading={loading}
        disableRowSelectionOnClick
        disableSelectionOnClick
      />
    </Box>
  );
}
