import Stack from '@mui/material/Stack';
import { Score } from '../../../../components/Score/Score';

export function DEI({ company }) {
  if (!company.sustainability_dimensions) {
    return null;
  }
  const {
    diversity_and_labor_rights,
  } = company.sustainability_dimensions;
  return (
    <Stack direction="row" justifyContent="center">
      <Score overall={diversity_and_labor_rights} title="DEI Overall" />
    </Stack>
  );
}
