import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

const validationSchema = yup.object({
  name: yup
    .string('Enter audit name')
    .min(1, 'Audit name should be of minimum 1 characters length')
    .max(30, 'Audit name should be of maximum 30 characters length'),
});

export function CreateAuditModal({ open, handleClose, modalProps }) {
  const theme = useTheme();
  // const { compareCompanies } = modalProps;

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
    },
  });

  const onClose = () => {
    handleClose();
    setLoading(false);
    formik.resetForm();
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      aria-labelledby="create-audit-dialog"
      aria-describedby="Create audit modal"
    >
      {handleClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.gray.main,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      <DialogContent sx={{ pt: 3, px: 4, pb: 4 }}>
        <Typography variant="h4" pb={4} align="center">Create audit</Typography>
        <form onSubmit={formik.handleSubmit}>
          <Stack direction="column" alignItems="center" spacing={3}>
            <TextField
              fullWidth
              name="name"
              label="Audit name"
              variant="standard"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <Button color="primary" variant="contained" type="submit" size="large" disabled={loading}>
              Create
            </Button>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
}
