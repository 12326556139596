import { Link } from 'react-router-dom';
import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MUILink from '@mui/material/Link';
import { useIsAuthenticated } from 'react-auth-kit';
import LogoIcon from '../../images/logo.png';
import { CompaniesAutocomplete } from '../CompaniesAutocomplete/CompaniesAutocomplete';
import { UserMenu } from './components/UserMenu/UserMenu';

const HeaderNav = styled(Stack)`
  height: 60px;
  border: 2px solid #c2cfd9;
`;

export function Header() {
  const isAuthenticated = useIsAuthenticated();

  return (
    <HeaderNav
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      px={2}
      py={1}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        <Link to="/">
          <img src={LogoIcon} alt="Logo" width="114px" />
        </Link>
        {
          isAuthenticated() && (
            <MUILink component={Link} to="/home">Home</MUILink>
          )
        }
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
      >
        {
          isAuthenticated() ? (
            <>
              <Box sx={{ width: 300 }}>
                <CompaniesAutocomplete />
              </Box>
              <UserMenu />
            </>
          ) : (
            <MUILink component={Link} to="/login">Log in</MUILink>
          )
        }
      </Stack>
    </HeaderNav>
  );
}
