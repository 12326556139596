import React from 'react';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { makeAvatarPlaceholder } from '../../../utils/helper';

export function Certifications({ company }) {
  const { sustainability_certifications = [] } = company;
  return (
    <Box>
      <Grid container spacing={2} alignItems="stretch">
        {
          sustainability_certifications?.length === 0 && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                minHeight: '200px',
              }}
            >
              <Typography variant="body1">No certifications</Typography>
            </Box>
          )
        }
        {
          sustainability_certifications.map((item) => (
            <Grid item key={item.id} sx={{ display: 'flex', alignItems: 'alignItems="stretch"' }}>
              <Paper
                variant="outlined"
                sx={{
                  minWidth: '120px', width: '120px', padding: 2,
                }}
              >
                <Stack direction="row" sx={{ justifyContent: 'center' }}>
                  <Avatar
                    variant="rounded"
                    src={item.logo_url}
                    sx={{
                      width: 70,
                      height: 70,
                      '& img': {
                        objectFit: 'contain',
                      },
                    }}
                  >
                    {makeAvatarPlaceholder(item.name)}
                  </Avatar>
                </Stack>
                <Box mt={1}>
                  <Typography variant="body1" fontWeight="bold" textAlign="center">{item.name}</Typography>
                </Box>
              </Paper>
            </Grid>
          ))
        }
      </Grid>
    </Box>
  );
}
