import axios from 'axios';

const API = process.env.REACT_APP_API_DOMAIN || '';

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.clear();
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  },
);

export const searchCompanies = ({
  name_starts = '',
}) => {
  const options = {
    method: 'GET',
    url: `${API}/companies/search?name_starts=${name_starts}`,
  };
  return axios(options)
    .then(({ data }) => data)
    .catch((error) => error);
};

export const retrieveCompany = ({
  company_id = '',
}) => {
  const options = {
    method: 'GET',
    url: `${API}/companies/${company_id}`,
  };
  return axios(options)
    .then(({ data }) => data)
    .catch((error) => error);
};
