import Stack from '@mui/material/Stack';
import { Score } from '../../../../components/Score/Score';

export function Climate({ company }) {
  if (!company.sustainability_dimensions) {
    return null;
  }
  const {
    energy_and_climate_change,
  } = company.sustainability_dimensions;
  return (
    <Stack direction="row" justifyContent="center">
      <Score overall={energy_and_climate_change} title="Climate Overall" />
    </Stack>
  );
}
