import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import Box from '@mui/material/Box';
import isNull from 'lodash/isNull';

export function Score({ overall, size = 70, title }) {
  const theme = useTheme();

  const percent = (overall * 100).toFixed(0);
  const color = useMemo(() => {
    if (isNull(overall)) {
      return theme.palette.scores.empty;
    }

    let value = theme.palette.scores.empty;

    if (percent < 75 && percent >= 50) {
      value = theme.palette.scores.warning;
    }

    if (percent < 50) {
      value = theme.palette.scores.error;
    }

    if (percent >= 75) {
      value = theme.palette.scores.success;
    }

    return value;
  }, [percent, theme.palette.scores.error, theme.palette.scores.success, theme.palette.scores.warning]);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      maxWidth: '100px',
    }}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: color.light,
        border: `3px solid ${color.main}`,
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: '50%',
      }}
      >
        <Typography variant="body1" fontWeight="bold">
          {isNull(overall) ? 'NA' : `${percent}%` }
        </Typography>
      </Box>
      {title && (
        <Typography variant="body2" fontWeight="bold" mt={1}>
          {title}
        </Typography>
      )}
    </Box>
  );
}
