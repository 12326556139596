import { useContext, forwardRef } from 'react';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { SnackbarContext } from '../../context/SnackbarContext/SnackbarContext';

const Alert = forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

export function CustomSnackbar() {
  const {
    snackbarOpen, snackbarMessage, snackbarSeverity = 'success', closeSnackbar,
  } = useContext(SnackbarContext);

  if (!snackbarMessage) return null;

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={5000}
      onClose={closeSnackbar}
    >
      <Alert severity={snackbarSeverity}>{snackbarMessage}</Alert>
    </Snackbar>
  );
}
