import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 700,
    },
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 700,
    },
  },
  palette: {
    primary: {
      main: '#830EDD',
    },
    secondary: {
      main: '#293845',
      light: '#788796',
    },
    gray: {
      main: '#4a5c6b',
      middle: '#C2CFD9',
      light: '#e3e8ed',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    scores: {
      success: {
        main: '#1aae9f',
        light: '#d1efec',
      },
      warning: {
        main: '#f7c325',
        light: '#fef3d4',
      },
      error: {
        main: '#d3455b',
        light: '#f7dade',
      },
      empty: {
        main: '#C2CFD9',
        light: '#e3e8ed',
      },
    },
  },
  components: {
    MuiChip: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            color: '#788796',
            borderColor: '#788796',
          },
        },
        {
          props: { variant: 'filled' },
          style: {
            color: '#fff',
            backgroundColor: '#788796',
          },
        },
        {
          props: { variant: 'white' },
          style: {
            backgroundColor: '#fff',
          },
        },
        {
          props: { variant: 'primary' },
          style: {
            color: '#fff',
            backgroundColor: '#830EDD',
            '&:hover': {
              backgroundColor: '#830EDD',
            },
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'text' },
          style: {
            textTransform: 'none',
          },
        },
      ],
    },
  },
});
