import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Goal1Icon from '../../../images/priority-goals/Goal-01.png';
import Goal2Icon from '../../../images/priority-goals/Goal-02.png';
import Goal3Icon from '../../../images/priority-goals/Goal-03.png';
import Goal4Icon from '../../../images/priority-goals/Goal-04.png';
import Goal5Icon from '../../../images/priority-goals/Goal-05.png';
import Goal6Icon from '../../../images/priority-goals/Goal-06.png';
import Goal7Icon from '../../../images/priority-goals/Goal-07.png';
import Goal8Icon from '../../../images/priority-goals/Goal-08.png';
import Goal9Icon from '../../../images/priority-goals/Goal-09.png';
import Goal10Icon from '../../../images/priority-goals/Goal-10.png';
import Goal11Icon from '../../../images/priority-goals/Goal-11.png';
import Goal12Icon from '../../../images/priority-goals/Goal-12.png';
import Goal13Icon from '../../../images/priority-goals/Goal-13.png';
import Goal14Icon from '../../../images/priority-goals/Goal-14.png';
import Goal15Icon from '../../../images/priority-goals/Goal-15.png';
import Goal16Icon from '../../../images/priority-goals/Goal-16.png';
import Goal17Icon from '../../../images/priority-goals/Goal-17.png';
import SDGLogoIcon from '../../../images/priority-goals/SDG-logo.png';

const goals = {
  Goal1Icon,
  Goal2Icon,
  Goal3Icon,
  Goal4Icon,
  Goal5Icon,
  Goal6Icon,
  Goal7Icon,
  Goal8Icon,
  Goal9Icon,
  Goal10Icon,
  Goal11Icon,
  Goal12Icon,
  Goal13Icon,
  Goal14Icon,
  Goal15Icon,
  Goal16Icon,
  Goal17Icon,
};

export function Priority() {
  const theme = useTheme();

  return (
    <div>Coming soon</div>
  );

  // eslint-disable-next-line
  return (
    <Stack direction="row" flexWrap="wrap" useFlexGap spacing={1}>
      {
        Array.from(Array(17).keys()).map((i) => (
          <Box key={i}>
            <img src={goals[`Goal${i + 1}Icon`]} alt="Goal" style={{ maxWidth: '86px' }} />
          </Box>
        ))
      }
      <Box
        sx={{
          width: '86px',
          height: '86px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme.palette.gray.light,
        }}
      >
        <img src={SDGLogoIcon} alt="SDG" style={{ maxWidth: '76px' }} />
      </Box>
    </Stack>
  );
}
