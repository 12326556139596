import Stack from '@mui/material/Stack';
import { Score } from '../../../../components/Score/Score';

export function Overall({ company }) {
  if (!company.sustainability_dimensions) {
    return null;
  }
  const { overall, environmental, social } = company.sustainability_dimensions;
  return (
    <>
      <Stack direction="row" justifyContent="center" mb={1}>
        <Score overall={overall} title="Overall" />
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Score overall={environmental} title="Environment" />
        <Score overall={social} title="Social" />
      </Stack>
    </>
  );
}
