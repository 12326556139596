import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import CancelIcon from '@mui/icons-material/Cancel';
import { CompareContext } from '../../utils/context';
import { makeAvatarPlaceholder } from '../../utils/helper';
import { useModal } from '../../hooks/useModal';
import { CreateAuditModal } from '../modals/CreateAuditModal/CreateAuditModal';

export function CompareCompaniesBox() {
  const theme = useTheme();
  const { compareCompanies, removeCompareCompany, removeAllCompareCompanies } = useContext(CompareContext);
  const {
    handleClose, handleOpen, openModal, modalProps,
  } = useModal();

  const onRemoveCompareCompany = (company) => () => {
    removeCompareCompany(company);
  };

  return (
    <>
      <Box sx={{
        position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: theme.palette.gray.light,
      }}
      >
        <Grid
          container
          alignItems="center"
          sx={{
            width: '100%', maxWidth: '991px', margin: '0 auto', py: 3,
          }}
        >
          <Grid item xs={10}>
            <Stack direction="row" flexWrap="wrap" useFlexGap spacing={2} pr={2}>
              {
                compareCompanies.map((company) => (
                  <Box sx={{ position: 'relative' }} key={company.id} onClick={onRemoveCompareCompany(company)}>
                    <Box sx={{
                      position: 'absolute', right: '-10px', top: '-10px', cursor: 'pointer', zIndex: 1,
                    }}
                    >
                      <CancelIcon />
                    </Box>
                    <Avatar sx={{ width: 54, height: 54 }}>{makeAvatarPlaceholder(company.name)}</Avatar>
                  </Box>
                ))
              }
            </Stack>
          </Grid>
          <Grid item xs={2}>
            <Stack direction="column" spacing={2}>
              <Button variant="contained" size="small" onClick={handleOpen({ compareCompanies })}>Compare Companies</Button>
              <Button variant="text" size="small" onClick={removeAllCompareCompanies}>Clear Selection</Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <CreateAuditModal
        open={openModal}
        modalProps={modalProps}
        handleClose={handleClose}
      />
    </>
  );
}
