import axios from 'axios';

const API = process.env.REACT_APP_API_DOMAIN || '';

export const signUp = ({
  name, email, password, successCallback = null, failCallback = null,
}) => {
  const options = {
    method: 'POST',
    data: {
      name,
      email,
      password,
    },
    url: `${API}/user`,
  };
  return axios(options)
    .then(({ data }) => data)
    .catch((error) => error);
};

export const login = ({
  email, password,
}) => {
  const options = {
    method: 'POST',
    data: {
      email,
      password,
    },
    url: `${API}/user/personal-access-token`,
  };
  return axios(options)
    .then(({ data }) => data)
    .catch((error) => error);
};

export const forgotPassword = ({
  email, return_url,
}) => {
  const options = {
    method: 'POST',
    data: {
      email,
      return_url,
    },
    url: `${API}/user/password-reset-notification`,
  };
  return axios(options)
    .then(({ data }) => data)
    .catch((error) => error);
};

export const resetPassword = ({
  email, password, token,
}) => {
  const options = {
    method: 'POST',
    data: {
      email,
      password,
      token,
    },
    url: `${API}/user/reset-password`,
  };
  return axios(options)
    .then(({ data }) => data)
    .catch((error) => error);
};

export const logOut = () => {
  const options = {
    method: 'DELETE',
    url: `${API}/user/personal-access-token`,
  };
  return axios(options)
    .then(({ data }) => data)
    .catch((error) => error);
};
