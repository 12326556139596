import React, { useState, useContext } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { Link, useNavigate } from 'react-router-dom';
import { useSignIn } from 'react-auth-kit';
import { AnAuthLayer } from '../../layouts/AnAuthLayer';
import { login } from '../../utils/api/auth';
import { SnackbarContext } from '../../context/SnackbarContext/SnackbarContext';
import { errorsParser } from '../../utils/helper';
import { FormWrapper } from './styles';

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});

export function Login() {
  const { openSnackbar } = useContext(SnackbarContext);
  const signIn = useSignIn();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const res = await login({ ...values });
      setLoading(false);

      if (res.data) {
        signIn({
          token: res.meta.personal_access_token,
          tokenType: 'Bearer',
          authState: res.data,
          expiresIn: 604800016,
        });
        navigate('/');
        openSnackbar('You are successfully logged in', 'success');
      } else if (res?.response?.data?.errors) {
        openSnackbar(errorsParser(res), 'error');
      }
    },
  });

  return (
    <AnAuthLayer title="Log in to your account">
      <FormWrapper
        elevation={3}
        sx={{
          px: 6, py: 6, mt: 3, borderRadius: 2,
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="column" spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="email"
                label="Email"
                variant="standard"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="password"
                label="Password"
                type="password"
                variant="standard"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="flex-end" mb={2}>
                <Link to="/forgot-password">
                  <Button variant="text">
                    Forgot password?
                  </Button>
                </Link>
              </Stack>
              <Button color="primary" variant="contained" fullWidth type="submit" size="large" disabled={loading}>
                Sign in
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormWrapper>
    </AnAuthLayer>
  );
}
