import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { SustainabilityMission } from './SustainabilityMission';
import { CorporateDetails } from './CorporateDetails';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`company-tabpanel-${index}`}
      aria-labelledby={`company-tab-${index}`}
      sx={{ pt: 2 }}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </Box>
  );
}

export function CompanyDetails({ company }) {
  const [value, setValue] = useState(0);
  const {
    impact_report_url,
    website_url,
  } = company.sustainability_details || {};

  const TABS = [
    {
      label: 'Sustainability Mission',
      component: <SustainabilityMission {...{ company }} />,
    },
    {
      label: 'Corporate Details',
      component: <CorporateDetails {...{ company }} />,
    },
    {
      label: 'Media',
      component: <div>Media coming soon</div>,
    },
    {
      label: 'News',
      component: <div>News coming soon</div>,
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box pb={3}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h5">Company Overview</Typography>
        <Stack direction="row" spacing={2}>
          {website_url && (
            <a
              target="_blank"
              href={`https://${website_url}`}
              rel="noreferrer"
            >
              <Chip
                sx={{ fontWeight: 'medium', cursor: 'pointer' }}
                variant="primary"
                size="small"
                label="Sustainability Website"
              />
            </a>
          )}
          {impact_report_url && (
            <a
              target="_blank"
              href={`https://${impact_report_url}`}
              rel="noreferrer"
            >
              <Chip
                sx={{ fontWeight: 'medium', cursor: 'pointer' }}
                variant="primary"
                size="small"
                label="Impact Report"
              />
            </a>
          )}
        </Stack>
      </Stack>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            {
              TABS.map(({ label }) => (
                <Tab key={label} label={label} />
              ))
            }
          </Tabs>
        </Box>
        {
          TABS.map(({ component, label }, index) => (
            <TabPanel key={label} value={value} index={index}>
              {component}
            </TabPanel>
          ))
        }
      </Box>
    </Box>
  );
}
