import Typography from '@mui/material/Typography';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { DetailContent } from '../../../../components';

export function CorporateDetails({ company }) {
  const theme = useTheme();
  const {
    address, locality, postal_code, country,
  } = company;

  return (
    <>
      <DetailContent title="Description">
        <Typography variant="body2" color={!company.description && theme.palette.gray.middle}>
          {company.description || 'No description found.'}
        </Typography>
      </DetailContent>
      <DetailContent title="Address">
        <Typography variant="body2">
          {address && `${address},`}
          {' '}
          {locality && `${locality},`}
          {' '}
          {postal_code && `${postal_code},`}
          {' '}
          {country && country}
        </Typography>
      </DetailContent>
    </>
  );
}
