export const QUERIES = [
  { label: 'Overall', value: 'overall' },
  { label: 'Environment', value: 'environment' },
  { label: 'Social', value: 'social' },

  { label: 'Climate', value: 'climate' },
  { label: 'DEI', value: 'DEI' },

  { label: 'Biodiversity', value: 'biodiversity' },
  { label: 'Human Rights', value: 'human_rights' },
];
