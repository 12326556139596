import Stack from '@mui/material/Stack';
import { Score } from '../../../../components/Score/Score';

export function Biodiversity({ company }) {
  if (!company.sustainability_dimensions) {
    return null;
  }
  const {
    resource_management,
  } = company.sustainability_dimensions;
  return (
    <Stack direction="row" justifyContent="center">
      <Score overall={resource_management} title="Resource Management Overall" />
    </Stack>
  );
}
