import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { AnAuthLayer } from '../../layouts/AnAuthLayer';
import { forgotPassword } from '../../utils/api/auth';
import { SnackbarContext } from '../../context/SnackbarContext/SnackbarContext';
import { errorsParser } from '../../utils/helper';
import { FormWrapper } from './styles';

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
});

export function ForgotPassword() {
  const navigate = useNavigate();
  const { openSnackbar } = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const res = forgotPassword({ ...values, return_url: `${window.location.origin}/reset-password` });
      setLoading(false);

      if (res?.response?.data?.errors) {
        openSnackbar(errorsParser(res), 'error');
      } else {
        navigate('/');
      }
    },
  });

  return (
    <AnAuthLayer>
      <FormWrapper
        elevation={3}
        sx={{
          px: 6, py: 6, mt: 3, borderRadius: 2,
        }}
      >
        <Typography variant="h5" mb={1}>
          Password Reset
        </Typography>
        <Typography variant="body2" mb={1}>
          Enter your email address below and we will send you a link for a password reset.
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="column" spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="email"
                label="Email address"
                variant="standard"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <Button color="primary" variant="contained" fullWidth type="submit" size="large" disabled={loading}>
                Send Password Reset Link
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" align="center">
                Return to
                <Link to="/login">
                  <Button variant="text">
                    Log in
                  </Button>
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </FormWrapper>
    </AnAuthLayer>
  );
}
