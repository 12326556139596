export const makeAvatarPlaceholder = (name = '') => {
  const nameParts = name.split(' ');
  return nameParts.map((part) => part[0]).join('');
};

export const errorsParser = (res) => {
  if (res?.response?.data?.errors) {
    return Object.values(res.response.data.errors)[0];
  }
  return null;
};
